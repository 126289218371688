import { defineStore } from "pinia";
import { useMPCWallet } from "./mpc_wallet";
import { useNormalWallet } from "./normal_wallet";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE, ISmartWallet } from "../api/auth/type";
import { isEmpty } from "lodash";
import PasswordService from "../security/PasswordService";
import Crypto from "../encrypt/EncryptionService";
import { useTraditionalWallet } from "./traditional_wallet";

export interface IWalletBase {
  address: any;
  smart_address: any;
  smart_data: ISmartWallet | undefined;
  signMessage: (message: string, option?: { hash?: boolean }) => Promise<`0x${string}`>;
  generateWallet: (pwd: string, hint: string) => Promise<any>;
  saveWalletToCloud?: (callbackProgress?: (progress: number) => void) => Promise<boolean>;
  tgCloudBackuped?: boolean;
  signTypedData: (payload: any) => Promise<`0x${string}`>;
  wallet_type: WALLET_TYPE;
  blockchain: BLOCKCHAIN_TYPE;
  name: string;
  clear: (id: string) => void;
  getSavedWallet: () => Promise<void>;
}

export const useWalletStore = (storeKey?: string) =>
  defineStore("wallet_store", () => {
    const wallets = ref<IWalletBase[]>([]);
    const currentSelectAddress = useLocalStorage("current_select_address", "");
    const { getUser } = useAuthStore();
    const normalWallet = useNormalWallet();
    const storePwd = storeKey || getUser().id;
    if (!storePwd) {
      // Should never have reached
      alert("Error when init wallet store");
      throw new Error("Error when init wallet store, missing store pwd");
    }
    const mpcWallet = useMPCWallet(storePwd);
    const traditionalWallet = useTraditionalWallet(storePwd);
    const initDone = ref(false);
    const pwdSaved = ref("");

    async function init() {
      console.log("init wallets");

      if (wallets.value.length > 0 && pwdSaved.value === PasswordService.getPassword()) return;

      pwdSaved.value = PasswordService.getPassword();
      wallets.value = [];
      // create MPC wallet
      await mpcWallet.getSavedWallet();
      await mpcWallet.checkSavedToTgCloud();

      wallets.value.push(mpcWallet);
      wallets.value.push(traditionalWallet);

      try {
        normalWallet.getSavedWallet();
        wallets.value.push(normalWallet);
      } catch (error) {
        console.log("normal wallet error", error);
      }

      initDone.value = true;
    }

    function setCurrentAddress(smart_address: string) {
      currentSelectAddress.value = smart_address;
    }

    function findWalletWithAddress(smart_address: string) {
      if (!smart_address) return null;
      return wallets.value.find((wallet) => wallet.smart_address === smart_address);
    }

    function findWalletByType(blockchain: BLOCKCHAIN_TYPE) {
      return wallets.value.find((wallet) => wallet.blockchain === blockchain);
    }

    function getCurrentWallet() {
      if (!currentSelectAddress.value) {
        return wallets.value[0];
      }
      return findWalletWithAddress(currentSelectAddress.value) || wallets.value[0];
    }

    function changePassword(newPwd: string) {
      // TODO
      const smart_wallets = getUser().smart_wallets;

      if (!isEmpty(smart_wallets)) {
        const currentPwd = PasswordService.getPassword();
        smart_wallets.forEach((w) => {
          const enc = w.encrypted_credential;
          const rawValue = Crypto.decrypt(enc, currentPwd);
          const newEnc = Crypto.encrypt(rawValue, newPwd);
          w.encrypted_credential = newEnc;
        });
      }
      return smart_wallets;
    }

    return {
      wallets: computed(() => wallets.value),
      existMPCWallet: computed(() => !!getUser().smart_wallets.find((w) => w.blockchain)),
      backup_info: computed(() => getUser().mpc_data?.backup_info || null),
      findWalletWithAddress,
      init,
      getCurrentWallet,
      findWalletByType,
      changePassword,
      setCurrentAddress,
      initDone,
    };
  })();
