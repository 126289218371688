import {
  mainnet,
  goerli,
  polygon,
  polygonAmoy,
  polygonMumbai,
  bsc,
  arbitrum,
  optimism,
  opBNB,
  avalanche,
  base,
  linea,
  mantle,
  manta,
  moonbeam,
  gnosis,
  zetachain,
  scroll,
  blast,
  cyber,
  polygonZkEvm,
  fantom,
  avalancheFuji,
  baseSepolia,
  baseGoerli,
  arbitrumNova,
  arbitrumGoerli,
  arbitrumSepolia,
  sei,
  seiTestnet,
  seiDevnet,
} from "viem/chains";
import { useWalletStore } from "./store";

export const LIMITED_CHAINS: number[] = [
  mainnet.id,
  polygon.id,
  bsc.id,
  arbitrum.id,
  optimism.id,
  avalanche.id,
  base.id,
  linea.id,
  opBNB.id,
  mantle.id,
  manta.id,
  moonbeam.id,
  gnosis.id,
  zetachain.id,
  scroll.id,
  blast.id,
  polygonZkEvm.id,
  sei.id,
];

export const LIMITED_TESTNET: number[] = [
  polygonAmoy.id,
  polygonMumbai.id,
  goerli.id,
  avalancheFuji.id,
  baseSepolia.id,
  baseGoerli.id,
  arbitrumNova.id,
  arbitrumGoerli.id,
  arbitrumSepolia.id,
];

export function checkChainSupported(chainId: number, smart_address?: string) {
  const wallets = useWalletStore();
  const wallet = smart_address ? wallets.findWalletWithAddress(smart_address) : wallets.getCurrentWallet();
  if (!wallet) return false;
  if (wallet.blockchain === "traditional") return true;
  return LIMITED_CHAINS.includes(chainId) || LIMITED_TESTNET.includes(chainId);
}

export function checkListChainSupported(chainIds: any[], smart_address?: string) {
  if (chainIds.length === 0) return true;
  let chains = [...chainIds];
  if (chainIds[0].includes("eip155")) chains = chainIds.map((chain) => Number(chain.replace("eip155:", "")));
  return chains.every((c) => checkChainSupported(c, smart_address));
}
